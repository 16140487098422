import React, { useState, useEffect } from 'react';
import './NavBar.scss';
import logo from '../../../assets/images/logo/LogoDiprofaq.png'; // Reemplaza con la ruta de tu logo

import ClearIcon from '@mui/icons-material/Clear';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, NavLink } from 'react-router-dom';

import data from "../../../data/products.json";

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    // Al cargar el componente, obtenemos las categorías con su índice y nombre
    const categoriasConIndice = data.categorias.map((categoria, index) => ({
      index,
      nombre: categoria.nombre,
    }));
    setCategorias(categoriasConIndice);
  }, []);

  // Detectar el cambio de tamaño de la pantalla
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsSmallScreen(screenWidth <= 768); // Detecta si la pantalla es de 768px o menor

      if (screenWidth > 768) {
        setIsOpen(false); // Cierra el menú cuando cambia el tamaño a mayor a 768px
      }
    };

    handleResize(); // Inicialmente ejecutamos para verificar el tamaño actual
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  // Detectar el scroll y añadir la clase 'scrolled-class' cuando el scroll es mayor al 10%
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      const scrolledPercentage = (scrollTop / (documentHeight - windowHeight)) * 100;

      if (scrolledPercentage >= 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Definir las clases de la navbar de forma dinámica
  const navbarClasses = `navbar ${isScrolled ? 'scrolled-class' : ''} ${isSmallScreen ? 'responsive-menu' : ''}`;

  return (
    <header className={navbarClasses}>
      <div className="navbar__logo">
        <Link to="/">
          <img src={logo} alt="Logo" className='animate__animated animate__fadeInDown' />
        </Link>
      </div>
      <nav className={`navbar__menu ${isOpen ? 'is-open' : ''}`}>
        <ul>
          <li className='animate__animated animate__fadeInUp'>
            <NavLink
              activeClassName="c-navbar__option--is-active"
              className={({ isActive }) => isActive ? "c-navbar__option c-navbar__option--is-active" : "c-navbar__option"}
              exact
              to="/">
              Inicio
            </NavLink>
          </li>

          <li className='animate__animated animate__fadeInUp'>
            <NavLink
              className={({ isActive }) => isActive ? "c-navbar__option c-navbar__option--is-active" : "c-navbar__option"}
              exact
              to="/diprofaq">
              ¿Quiénes somos?
            </NavLink>
          </li>
          <li
            className="has-submenu animate__animated animate__fadeInUp z--index-component"
            onClick={toggleSubMenu}
            onMouseEnter={() => setIsSubMenuOpen(true)}
            onMouseLeave={() => setIsSubMenuOpen(false)}
          >
            <a className=''>
              Productos
            </a>

            <ul className={`submenu ${isSubMenuOpen ? 'is-open' : ''}`}>
              {categorias.map((categoria) => (
                <li key={categoria.index + 'url-navbar'}>
                  <NavLink
                    activeClassName="c-navbar__option--is-active"
                    className="c-navbar__option "
                    exact
                    to={'/products/' + categoria.index}>
                    {categoria.nombre}
                  </NavLink>
                </li>
              ))}
            </ul>
          </li>

          <li className='animate__animated animate__fadeInUp'>
            <NavLink
              className={({ isActive }) => isActive ? "c-navbar__option c-navbar__option--is-active" : "c-navbar__option"}
              exact
              to="/distributors">
              Distribuidores
            </NavLink>
          </li>

          <li className='animate__animated animate__fadeInUp'>
            <NavLink
              className={({ isActive }) => isActive ? "c-navbar__option c-navbar__option--is-active" : "c-navbar__option"}
              exact
              to="/contact">
              Contáctanos
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className="navbar__toggle" onClick={toggleMenu}>
        {isOpen ? <ClearIcon /> : <MenuIcon />} {/* Cambia el ícono al abrir/cerrar */}
      </div>
    </header>
  );
}

export default NavBar;
