import React from 'react';
import './About.scss';
import { useInView } from 'react-intersection-observer';

import pet1 from '../../../assets/images/about/QuienesSomos_2.png';
import pet2 from '../../../assets/images/about/QuienesSomos_3.png';
import pet3 from '../../../assets/images/about/QuienesSomos_4.png';


import videoSrc from '../../../assets/images/about/QuienesSomos_1.mp4'; // Reemplaza esto con la ruta a tu video.

const About = () => {
  const [refMission, inViewMission] = useInView({ threshold: 0.2, triggerOnce: false });
  const [refVision, inViewVision] = useInView({ threshold: 0.2, triggerOnce: false });
  const [refPhilosophy, inViewPhilosophy] = useInView({ threshold: 0.2, triggerOnce: false });

  const scrollToNextSection = (id) => {
    const nextSection = document.getElementById(id);
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="about">
      {/* <main className="about__content">
        <section className="about__intro">
          <h2 className="about__subtitle">Somos Diprofa'q</h2>
          <p className="about__description">
          Nuestro objetivo principal es elaborar productos farmacéuticos veterinarios de calidad para mantener la satisfacción de nuestros clientes y mantener una población de animales saludables. Aunado a esto, mantener una política con nuestros representantes de ganar ganar; basada en la confianza y la honestidad. Nos comprometemos a fomentar el cumplimiento de nuestros valores y principios para lograr que nuestro objetivo sea una realidad.
          </p>
          <div className="scroll-button">
            <button onClick={() => scrollToNextSection('mission-section')}>
              <span className="arrow-down">↓</span>
            </button>
          </div>
          <div className="about__small-dogs">
            <img src={pet1} alt="Dog 1" className="small-dog" />
            <img src={pet2} alt="Dog 2" className="small-dog" />
          </div>
        </section>

        <section className="about__large-dogs">
          <img src={pet3} alt="Dog 3" className="large-dog" />
          <img src={pet4} alt="Dog 4" className="large-dog" />
        </section>
      </main> */}

      <div
        className={`we-love-pets ${inViewMission ? 'visible' : 'visible'}`}
        id="about-diprofaq"
        ref={refMission}
      >
        <div className="we-love-pets__content">
          <h2>Somos Diprofa'q</h2>
          <p>Nuestro objetivo principal es elaborar productos farmacéuticos veterinarios de calidad para mantener la satisfacción de nuestros clientes y mantener una población de animales saludables. Aunado a esto, mantener una política con nuestros representantes de ganar ganar; basada en la confianza y la honestidad. Nos comprometemos a fomentar el cumplimiento de nuestros valores y principios para lograr que nuestro objetivo sea una realidad.</p>
          {/* <div className="scroll-button">
            <button onClick={() => scrollToNextSection('mission-section')}>
              <span className="arrow-down">↓</span>
            </button>
          </div> */}
        </div>
        <div className="we-love-pets__image">
          {/* <img src={pet1} alt="Cat" /> */}
          <video
            src={videoSrc}
            autoPlay
            loop
            muted
            playsInline
            className="about-video"
          >
            Tu navegador no soporta la etiqueta de video.
          </video>
        </div>
      </div>

      {/* Mission Section */}
      <div
        className={`we-love-pets c-reorder-responsive ${inViewMission ? 'visible' : 'hidden'}`}
        id="mission-section"
        ref={refMission}
      >

        <div className="we-love-pets__image">
          <img src={pet1} alt="Cat" />
        </div>
        <div className="we-love-pets__content">
          <h2>Misión</h2>
          <p>Nuestra misión es mantener y acrecentar nuestra línea de productos con calidad y profesionalismo, involucrando a todas las partes que intervienen en todo nuestro proceso; empleados, distribuidores y vendedores, para así obtener la satisfacción buscada por nuestros clientes.</p>
          {/* <div className="scroll-button">
            <button onClick={() => scrollToNextSection('vision-section')}>
              <span className="arrow-down">↓</span>
            </button>
          </div> */}
        </div>
      </div>

      {/* Vision Section */}
      <div
        className={`we-love-pets ${inViewVision ? 'visible' : 'hidden'}`}
        id="vision-section"
        ref={refVision}
      >

        <div className="we-love-pets__content">
          <h2>Visión</h2>
          <p>DIPROFA´Q mira hacia el futuro innovando productos farmacéuticos veterinarios con el afán de hacer más práctico y accesible el tratamiento de los pacientes y médicos veterinarios involucrados. Nunca pretendemos copiar marcas, sino por el contrario hacer un producto lo mas diferente y único posible para satisfacer los requerimientos que los médicos veterinarios reclaman.</p>
          {/* <div className="scroll-button">
            <button onClick={() => scrollToNextSection('philosophy-section')}>
              <span className="arrow-down">↓</span>
            </button>
          </div> */}
        </div>
        <div className="we-love-pets__image">
          <img src={pet2} alt="Cat" />
        </div>
      </div>

      {/* Philosophy Section */}
      <div
        className={`we-love-pets c-reorder-responsive ${inViewPhilosophy ? 'visible' : 'hidden'}`}
        id="philosophy-section"
        ref={refPhilosophy}
      >
        <div className="we-love-pets__image">
          <img src={pet3} alt="Quienes somos 4" />
        </div>
        <div className="we-love-pets__content">
          <h2>Filosofía</h2>
          <p>Desde su nacimiento DIPROFA´Q ha mantenido y pretendido avanzar dentro de la Industria Veterinaria con profesionalismo, calidad, respeto y honestidad. Valorando el esfuerzo de nuestro personal y de nuestros representantes, así como también aquilatando en demasía la confianza depositada en nuestros productos. Como parte final de todo esto, trabajamos en conjunto con la convicción de lograr la satisfacción de toda persona involucrada en el sector veterinario.</p>
        </div>
      </div>
    </div>
  );
};

export default About;
