import React from 'react'

import './Diprofaq.scss';
import About from './components/About';
import Footer from '../../shared/footer/Footer';
import NavBar from '../../shared/components/navbar/Navbar';

const Diprofaq = () => {
  return (
    <>
      <NavBar />
      <About />
      <Footer />
    </>
  );
}

export default Diprofaq;