import React from 'react'

import { useMediaQuery } from "@mui/material";

import NavBar from '../../shared/components/navbar/Navbar'

import videoSrc from '../../assets/video/video.mp4'; 
import videoMobileSrc from '../../assets/video/video-mobile.mp4'; 

import { useInView } from 'react-intersection-observer';

// import quienesSomos from '../../assets/images/diprofaq-somos.jpg';
import pet from '../../assets/images/pets-v2/Nosotros.png';
import Footer from '../../shared/footer/Footer';
import ProductLines from './secctions/ProductLines';

import './Home.scss';

const Home = () => {

  const { ref, inView } = useInView({ threshold: 0.1, triggerOnce: false });

  const isSmallScreen = useMediaQuery("(max-width:437px)");

  return (
    <div>
      <NavBar />
      <div className="full-screen-container">
        {/* <img src={bg} alt="Fondo" className="background-image" /> */}
        <video
          src={isSmallScreen ? videoMobileSrc : videoSrc}
          autoPlay
          loop
          muted
          playsInline
          className="background-video"
        >
          Tu navegador no soporta la etiqueta de video.
        </video>
        <div className="overlay">
          <div className="overlay-container text-container div-20">
            {/* <h1 className='animate__animated animate__fadeInDown text-farmaceuticos'>
              Productos farmacéuticos

            </h1>
            <span className="highlight animate__animated animate__fadeInDown">VETERINARIOS</span> */}
            {/* <p className='animate__animated animate__fadeInUp'>
              <span className="years-highlight">30</span> años al servicio de tu mejor amigo.
            </p> */}
          </div>
          <div className="overlay-container div-80">
          </div>
        </div>
      </div>

      <div className='c-container-main-sections'>
        <section className={`about-us ${inView ? 'visible' : 'hidden'}`} ref={ref}>
          <div className="about-us__text">
            <h2>Nosotros</h2>
            <p>
              Nuestro objetivo principal es elaborar productos farmacéuticos veterinarios de calidad para mantener la satisfacción de nuestros clientes y mantener una población de animales saludables. Aunado a esto, mantener una política con nuestros representantes de ganar ganar; basada en la confianza y la honestidad. Nos comprometemos a fomentar el cumplimiento de nuestros valores y principios para lograr que nuestro objetivo sea una realidad.
            </p>
          </div>
          <div className="about-us__image">
            <img src={pet} alt="Empresa" />
          </div>
        </section>
      </div>

      <ProductLines />
      {/* <ProductsSection /> */}
      <Footer />
    </div>

  )
}

export default Home