import React, { useEffect, useState } from 'react'

import './Distributors.scss';

import image from '../../assets/images/distributors/MapaDistribuidoresV2.png';
import NavBar from '../../shared/components/navbar/Navbar';
import Footer from '../../shared/footer/Footer';

import videoSrcAnimacion from '../../assets/video/animaciones/01PerritoClaroAcostado.mp4';


const StatItem = ({ endValue, label }) => {
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    let startValue = 0;
    const duration = 1500; // Duración de la animación en milisegundos
    const incrementTime = 50; // Cada cuanto tiempo aumentar el número

    const increment = endValue / (duration / incrementTime);

    const timer = setInterval(() => {
      startValue += increment;
      if (startValue >= endValue) {
        startValue = endValue;
        clearInterval(timer);
      }
      setCurrentValue(Math.floor(startValue)); // Redondea el valor hacia abajo
    }, incrementTime);

    return () => clearInterval(timer);
  }, [endValue]);

  return (
    <div className="stat-item">
      <h2>+ {currentValue}</h2>
      <p>{label}</p>
    </div>
  );
};

const Distributors = () => {
  return (
    <>
      <NavBar />
      <section className="global-section">
        <div className="content">
          <h1 className="title">Tenemos presencia en toda la República Mexicana</h1>
          {/* <p className="subtitle">
            Comprometidos con la salud animal en cada rincón de México, nuestros productos llegan donde más se necesitan.
          </p> */}

          {/* <div className="select-service">
          <select className="country-select">
            <option value="india">India</option>
            <option value="brazil">Brazil</option>
            <option value="usa">USA</option>
          </select>
          <button className="service-button">See Services</button>
        </div> */}

          <div className="map">
            {/* Aquí puedes agregar la imagen del mapa */}
            <img src={image} alt="World Map" className="map-image  animate__animated animate__pulse" />
          </div>


          <div className="stats">
            {/* <StatItem endValue={86} label="Productos" /> */}
            {/* <StatItem endValue={190} label="Clientes" /> */}
            <StatItem endValue={93} label="Distribuidores" />
          </div>
        </div>
        
      <video
        src={videoSrcAnimacion}
        autoPlay
        loop
        muted
        playsInline
        className="video-animation"
      >
        Tu navegador no soporta la etiqueta de video.
      </video>
      </section>

      <Footer />
    </>
  )
}

export default Distributors