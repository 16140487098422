import React, { useEffect, useState } from 'react';

import { Navigate, useNavigate, useParams } from 'react-router-dom';
import data from "../../data/products.json";

import NavBar from '../../shared/components/navbar/Navbar';
import Footer from '../../shared/footer/Footer';


import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { DialogDetailProduct } from './dialog-detail-product/DialogDetailProduct';


import '../home/secctions/ProductsSection.scss';

import videoSrcAnimacion1Acostado from '../../assets/video/animaciones/01PerritoClaroAcostado.mp4';
import videoSrcAnimacion2 from '../../assets/video/animaciones/02PerritoClaroComiendo.mp4';
import videoSrcAnimacion3 from '../../assets/video/animaciones/03PerritoClaroDurmiendo.mp4';
import videoSrcAnimacion4 from '../../assets/video/animaciones/04PerritoClaroFeliz.mp4';






const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  maxHeight: '90vh', // Limita la altura del modal al 80% del viewport
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 0,
  overflowY: 'auto', // Agrega el scroll vertical si el contenido es mayor que el modal
  borderRadius: '15px', // Para bordes redondeados si lo deseas
};

const closeButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '15px',
  background: 'transparent',
  border: 'none',
  fontSize: '3rem',
  cursor: 'pointer',
};


// Función para obtener los productos por el índice de categoría
const obtenerCategoriaPorIndice = (indiceCategoria) => {
  // Validamos si el índice está dentro del rango de categorías existentes
  if (indiceCategoria >= 0 && indiceCategoria < data.categorias.length) {
    return data.categorias[indiceCategoria];
  }
  return null;
};

const Products = () => {
  const { info } = useParams(); // Obtener el parámetro 'info' de la URL (el índice)
  const [productos, setProductos] = useState([]);
  const [categoriaNombre, setCategoriaNombre] = useState('');
  const [categoriaValida, setCategoriaValida] = useState(true);



  const [id, setId] = useState(0);


  const navigate = useNavigate();


  const [idOpen, setIdOpen] = React.useState(null);
  // const handleOpen = () => setidOpen(true);
  const handleClose = () => setIdOpen(null);

  const obtenervideoAnimacion = (indice) => {
    switch (indice) {
      case 0:
        return videoSrcAnimacion3;
      case 1:
        return videoSrcAnimacion4;
      case 2:
        return videoSrcAnimacion2;
      case 3:
        return videoSrcAnimacion1Acostado;
      case 4:
        return videoSrcAnimacion4;
      case 5:
        return videoSrcAnimacion2;
      case 6:
        return videoSrcAnimacion3;
      case 7:
        return videoSrcAnimacion4;
      case 8:
        return videoSrcAnimacion1Acostado;
      case 9:
        return videoSrcAnimacion2;
      default:
        return videoSrcAnimacion3;
    }
  }


  const obtenerClaseDireccion = (indice) =>{

    if(indice == 2) {
      return '';
    }

    if(indice % 2 == 0) {
      return 'video-transform-origin'; 
    } return ''
  }

  useEffect(() => {
    const indiceCategoria = parseInt(info, 10); // Convertimos 'info' a un número entero

    // Si 'info' es un número y la categoría existe
    if (!isNaN(indiceCategoria)) {
      const categoriaEncontrada = obtenerCategoriaPorIndice(indiceCategoria);

      if (categoriaEncontrada) {
        setProductos(categoriaEncontrada.productos); // Seteamos los productos de la categorí
        setCategoriaNombre(categoriaEncontrada.nombre);
        setId(indiceCategoria);
      } else {
        console.log('No es válida porque no se encontro info')
        setCategoriaValida(false); // Si la categoría no se encuentra, se marca como inválida
      }
    } else {
      setCategoriaValida(false); // Si 'info' no es un número, se redirige a home
      console.log('No es valida por NAN');
    }
  }, [info]);

  if (!categoriaValida) {
    // Si la categoría no es válida, redirigimos a la página de inicio
    return <Navigate to="/" />;
  }



  return (

    <>
      <NavBar />

      <div className="birds-gallery">
        <h3 className='birds-gallery__title'>Nuestros productos {categoriaNombre}</h3>

        {/* Condición para mostrar un mensaje si no hay productos */}
        {productos?.length === 0 || !productos ? (
          <div className="no-productos">
            <p>Lo sentimos, esta categoría no tiene productos disponibles en este momento.</p>

            <p className="product-card__details" onClick={() => {
              navigate('/')
            }} >Volver a inicio</p>
          </div>
        ) : (
          <div className="birds-gallery__grid">
            {productos?.map((producto, index) => (
              <div className="product-card" key={`p-${index}`}
                onClick={() => {
                  setIdOpen(index)
                }}
              >
                <div className="product-card__image-container">
                  <img src={process.env.PUBLIC_URL + producto.imagenUrl} alt={producto.nombre} className="product-card__image" />
                </div>
                <div className="product-card__content">
                  <h3>{producto.nombre}</h3>
                  <p>{producto.registro}</p>
                  <p className="product-card__details">Detalles</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>


      {
        (id != null || id != undefined) &&
        (<div
          className='video-main-container bottom-space-end-final'
        >
          <video
            src={obtenervideoAnimacion(id)}
            autoPlay
            loop
            muted
            playsInline
            className={`video-animation ${obtenerClaseDireccion(id)}`}
          >
            Tu navegador no soporta la etiqueta de video.
          </video>
        </div>)
      }



      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={idOpen != null}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={idOpen != null}>
          <Box sx={style}>
            <button style={closeButtonStyle} onClick={handleClose}>
              &times;
            </button>
            <DialogDetailProduct id="transition-modal-description" indexCategory={info} indexProduct={idOpen} />
            {/* <Typography  >
                            </Typography> */}
          </Box>
        </Fade>
      </Modal>


      <Footer />
    </>

  )
}

export default Products