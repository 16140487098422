import React from 'react';
import './Footer.scss';

import logo from '../../assets/images/logo/LogoDiprofaqBlanco.png'; // Reemplaza con la ruta de tu logo

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__header">
                {/* <h1 className="footer__company-name">DIPROFA'Q</h1>
                <p className="footer__company-tagline">Productos farmacéuticos VETERINARIOS.</p> */}
                <img src={logo} alt='demo' />

            </div>

            <div className="footer__main">

                {/* <div className="footer__section">
                    <h3>Contenido</h3>
                    <ul>
                        <li className='animate__animated animate__fadeInUp'>
                            <NavLink
                                activeClassName="c-navbar__option--is-active"
                                className="c-navbar__option"
                                exact
                                to="/">
                                Inicio
                            </NavLink>
                        </li>

                        <li className='animate__animated animate__fadeInUp'>
                            <NavLink
                                activeClassName="c-navbar__option--is-active"
                                className="c-navbar__option"
                                exact
                                to="/diprofaq">
                                Quienes somos
                            </NavLink>
                        </li>


                        <li className='animate__animated animate__fadeInUp'>
                            <NavLink
                                activeClassName="c-navbar__option--is-active"
                                className="c-navbar__option "
                                exact
                                to="/distributors">
                                Distribuidores
                            </NavLink>
                        </li>

                        <li className='animate__animated animate__fadeInUp'>
                            <NavLink
                                activeClassName="c-navbar__option--is-active"
                                className="c-navbar__option"
                                exact
                                to="/contact">
                                Contacto
                            </NavLink>
                        </li>
                    </ul>
                </div> */}
                <div className="footer__section">
                    <h3>Contacto</h3>
                    <ul>
                        <li><a href="mailto:diprofaq@hotmail.com">Correo: diprofaq@hotmail.com</a></li>
                        <li><a href="tel:+525959558075">Teléfono: 59 59 55 80 75</a></li>

                    </ul>
                </div>
                {/* <div className="footer__section">
          <h3>Redes sociales</h3>
          <ul>
            <li><a href="https://twitter.com">Twitter</a></li>
            <li><a href="https://linkedin.com">LinkedIn</a></li>
            <li><a href="https://facebook.com">Facebook</a></li>
            <li><a href="https://github.com">GitHub</a></li>
          </ul>
        </div> */}


                <div className="footer__section">
                    <h3>Dirección</h3>
                    <ul>
                        <li><a>Cerrada San Cristóbal N. 4, Col. Tulantongo,</a></li>
                        <li><a>Texcoco, Estado de México</a></li>

                    </ul>
                </div>
            </div>

            <div className="footer__bottom">
                <p className="footer__copyright">&copy; {new Date().getFullYear()} DIPROFA'Q. Todos los derechos reservados.</p>
                {/* <div className="footer__social-icons">
                    <a href="https://twitter.com"><i className="fab fa-twitter"></i></a>
                    <a href="https://linkedin.com"><i className="fab fa-linkedin"></i></a>
                    <a href="https://facebook.com"><i className="fab fa-facebook"></i></a>
                    <a href="https://github.com"><i className="fab fa-github"></i></a>
                </div> */}
            </div>
        </footer>
    );
};

export default Footer;
