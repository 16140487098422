import React from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Button, Snackbar, IconButton } from '@mui/material';
import './ContactForm.scss';

import NavBar from '../../shared/components/navbar/Navbar';
import Footer from '../../shared/footer/Footer';

import videoSrcAnimacion from '../../assets/video/animaciones/04PerritoClaroFeliz.mp4';

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [open, setOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="primary" size="small" onClick={handleClose}>
        Cerrar
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        {/* Puedes agregar un icono de cerrar aquí si lo deseas */}
      </IconButton>
    </React.Fragment>
  );

  const onSubmit = (data) => {
    // Prepara los datos para el servicio REST
    const payload = {
      nombre: data.nombre,
      correo: data.email, // Mapeamos 'email' a 'correo'
      telefono: data.telefono,
      mensaje: data.mensaje,
    };

    // Envía una solicitud POST al servicio REST
    fetch('https://4n4pfm9hwb.execute-api.us-east-2.amazonaws.com/dev/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.ok) {
          // Manejo de respuesta exitosa
          setSnackbarMessage('Mensaje enviado con éxito');
          setOpen(true);
          reset(); // Reinicia el formulario
        } else {
          // Manejo de errores del servidor
          setSnackbarMessage('Hubo un error al enviar el mensaje');
          setOpen(true);
        }
      })
      .catch((error) => {
        // Manejo de errores de red
        console.error('Error:', error);
        setSnackbarMessage('Hubo un error al enviar el mensaje');
        setOpen(true);
      });
  };

  return (
    <>
      <NavBar />

      <div className="contact-container">
        {/* Encabezado */}
        <div className="contact-header">
          <h1>¡Contáctanos!</h1>
          <p>Escríbenos tus comentarios o dudas.</p>
        </div>

        {/* Formulario */}
        <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-grid">
            {/* Campos de entrada */}
            <div className="left-column">
              <div className="form-field">
                <TextField
                  label="Nombre"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  {...register('nombre', { required: 'El nombre es requerido' })}
                  error={!!errors.nombre}
                  helperText={errors.nombre?.message}
                />
              </div>

              <div className="form-field">
                <TextField
                  label="Correo electrónico"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  {...register('email', {
                    required: 'El correo es requerido',
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: 'El formato del correo no es válido',
                    },
                  })}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              </div>

              <div className="form-field">
                <TextField
                  label="Teléfono"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  {...register('telefono', {
                    required: 'El teléfono es requerido',
                    pattern: {
                      value: /^[0-9]+$/,
                      message: 'El teléfono solo puede contener números',
                    },
                  })}
                  error={!!errors.telefono}
                  helperText={errors.telefono?.message}
                />
              </div>

              <div className="form-field">
                <TextField
                  label="Mensaje"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  multiline
                  rows={5}
                  {...register('mensaje', {
                    required: 'El mensaje es requerido',
                    minLength: {
                      value: 10,
                      message: 'El mensaje debe tener al menos 10 caracteres',
                    },
                  })}
                  error={!!errors.mensaje}
                  helperText={errors.mensaje?.message}
                />
              </div>
            </div>
          </div>

          <video
            src={videoSrcAnimacion}
            autoPlay
            loop
            muted
            playsInline
            className="video-animation"
          >
            Tu navegador no soporta la etiqueta de video.
          </video>

          {/* Botón de envío */}
          <div className="form-field form-field--center">
            <Button variant="contained" color="primary" type="submit" className="button-style">
              Enviar mensaje
            </Button>
          </div>
        </form>
      </div>

      {/* Snackbar */}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbarMessage}
        action={action}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />

      <Footer />
    </>
  );
};

export default Contact;
