

import './App.scss';

import { HashRouter as Router } from 'react-router-dom';
import RoutesConfig from './RoutesConfig';
import ScrollToTop from './ScrollToTop';

function App() {
  return (
    <div className='c-main-container'>
      <Router>
      <ScrollToTop /> {/* Este componente se ejecutará en cada cambio de ruta */}
        {/* <div> */}
        {/* Define the routes */}
        <RoutesConfig />
        {/* </div> */}
      </Router>
    </div>
  );
}

export default App;
