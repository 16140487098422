
import './_about.scss';

import image from '../../logo.svg';


function About() {
    return (
        <div className="About">
            <div className="services-section">
                <div className="content">
                    <h2>We Can Find the Right Solutions for Your Business.</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat duis aute irure dolor.
                    </p>
                    <button className="watch-video-btn">Watch video</button>
                </div>
                <div className="video-thumbnail">
                    <img src={image} alt="Video thumbnail" />
                    <div className="play-icon">
                        {/* Aquí podrías poner un ícono SVG de play o utilizar una imagen */}
                        <span>&#9658;</span> {/* Esto es un símbolo de play "►" */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
