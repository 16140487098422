import React from 'react'


// import '../../../assets/images/lines-products'

import './ProductLines.scss';

import data from "../../../data/products.json";
import { useNavigate } from 'react-router-dom';

const ProductLines = () => {

  const navigate = useNavigate();

  return (
    <div className="product-lines-container bottom-space-end-final">
      <h2 className='title-section'>Nuestras líneas de productos</h2>
      <div className="product-lines-grid">
        {data.categorias.map((line, index) => (
          <div key={`category-${index}`} className="product-line" onClick={
            ()=>{
              navigate('/products/'+index)
            }
          }>
            <h3>{line.nombre}</h3>
            <div className="image-container">
              <img src={process.env.PUBLIC_URL + line.image_url} alt={line.nombre} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProductLines