import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Products from './pages/products/Products';
import Diprofaq from './pages/diprofaq/Diprofaq';
import Distributors from './pages/distributors/Distributors';

const RoutesConfig = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/products/:info" element={<Products />} />
      <Route path="/diprofaq" element={<Diprofaq />} />
      <Route path="/distributors" element={<Distributors />} />

       {/* Redirige todas las rutas no encontradas a Home */}
       <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default RoutesConfig;