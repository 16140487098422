import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth' // Agregamos el comportamiento de scroll suave
      });
    }, [pathname]); // Ejecutar cada vez que cambie la ruta (pathname)
  
    return null; // Este componente no renderiza nada en la interfaz
  };
  
  export default ScrollToTop;